@import '~antd/dist/antd.css';

.body-container {
  min-height: 100vh;
  background:#fbfcfd;
  .main-app-container {
    margin: auto;
    width: 100%;
    overflow: auto;
    position: relative;
  }
}

@media(min-width: 650px) {
  .body-container {
    padding-top: 40px;

    .main-app-container {
      padding: 20px 10px;
      background: #fff;
      width: 70%;
    }
  }
}

@media(min-width: 768px) {
  .body-container {
    .main-app-container {
      width: 80%;
      border: 1px solid rgba(77, 80, 85, 0.1);
      border-radius: 8px;
      box-shadow: 0 1px 8px 0 #00000014;
    }
  }
}

@media(min-width: 992px) {
  .body-container {
    .main-app-container {
      width: 90%;
      height: 700px;
    }
  }
}

@media(min-width: 1200px) {
  .body-container {
    .main-app-container {
      max-width: 1060px;
      min-width: 900px;
      margin-left: auto;
      margin-right: auto;
      padding: 20px 10px;
    }
  }
}
